import React from 'react';
import './ContentSection.css';

import { Card } from '../Card/Card';
import { Contact } from '../Contact/Contact';


export const ContentSection = ({ section_info }) => {
    return (
        <section id={section_info.id} className="section">
            <h2>{section_info.header}</h2>

            {section_info.content ? (
                <p>{section_info.content}</p>
            ) : (
                <></>
            )}
            {section_info.type === 'card' && (
                <div >
                    <Card card_info={section_info.card}/>
                </div>
            )}
            {section_info.type === 'contact' && (
                <div >
                    <Contact contact_info={section_info.contact}/>
                </div>
            )}
        </section>
    );
};