import React from 'react';
import './Footer.css';


export const Footer = () => {

    return (
        <footer className="footer">
        <p>&copy; 2024 My Portfolio. All rights reserved.</p>
        <p>
          <a href="https://www.flaticon.com/de/kostenloses-icon/band_4570043?related_id=4570043" target="_blank" rel="noopener noreferrer">
            Icon Link
          </a>
        </p>
      </footer>
    );
};


