import React from 'react';

import { NavBar } from '../../Content/NavBar/NavBar';
import { Footer } from '../../Content/Footer/Footer';

export const LoginPage = () => {
    
    return (
        <div className="HomePage">
            <NavBar />
            <div className="section">
                <h1>Login Page</h1>
                <p>Comming soon</p>
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />                
            </div>
            <Footer />
        </div>
    );
};