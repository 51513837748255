import { HomePage } from './Content/HomePage/HomePage';
import { LoginPage } from './AdminPage/LoginPage/LoginPage';

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

function App() {
  return (
    <Router>
			<Routes>
				<Route
					path="/"
					element={
						<div>
							<HomePage />
						</div>
					}
				/>
				<Route
					path="login"
					element={
						<div>
							<LoginPage />
						</div>
					}
				/>
			</Routes>
		</Router>
  );
}

export default App;
