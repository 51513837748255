import React from "react";
import "./Contact.css";

export const ContactContent = ({ contact_info }) => {
	return (
		<p>
			<a href={contact_info.link} target="_blank" rel="noopener noreferrer">
				{contact_info.link_text}
			</a>
		</p>
	);
};

export const Contact = ({ contact_info }) => {
	return (
		<div className="contact">
			{contact_info.map((item) => (
				<ContactContent contact_info={item} />
			))}
		</div>
	);
};
