import React from 'react';
import './Card.css';


export const CardContent = ({card_info_content}) => {

    return (
        <div className="project-card">
            <h3>{card_info_content.header}</h3>
            <p>{card_info_content.content}</p>
            <a href={card_info_content.link} target="_blank" rel="noopener noreferrer">
                {card_info_content.link_text}
            </a>
        </div>
    );

}

export const Card = ({card_info}) => {
    return (
        <div className="projects">
            {card_info.map((item) => (
                <CardContent
                    card_info_content={item}
                />
            ))}
        </div>
    );
};